import { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { Link, NavLink } from 'react-router-dom';
import Logo from '../Logo/Logo';
import './Header.scss';
import menuItems from '../../data/menuItems';

function OverlayMenu(props: {
  onItemClick: () => void,
  onBluredAreaClick: () => void,
}) {
  return createPortal(
    <div className='overlay-menu overlay'>
      <div className='flex center'>
        <hr className='divider base-width' />
      </div>
      <ul className='flex column middle'>
      {menuItems.map((item, i) => (
        <li key={i}>
          {item.external ? (
            <a href={item.uri} target="_blank" rel="noopener noreferrer">{item.title}</a>
          ) : (
            <NavLink to={item.uri} replace>{item.title}</NavLink>
          )}
        </li>
))}
      </ul>
      <div className='overlay' onClick={() => props.onBluredAreaClick()} />
    </div>,
    document.getElementById('portal')!
  );
}

export default function Header() {
  const [overlayMenuOpen, setOverlayMenuOpen] = useState(false);

  useEffect(() => {
    if(overlayMenuOpen) {
      window.scrollTo(0, 0);
      document.body.classList.add('overlay-menu-visible');
    } else {
      document.body.classList.remove('overlay-menu-visible');
    }
  }, [overlayMenuOpen]);

  return (
    <>
      <header className='flex center'>
        <div className='flex base-width'>
          <div className='logo-wrapper'>
            <Link to="/" onClick={() => setOverlayMenuOpen(false)}>
              <Logo />
            </Link>
          </div>
          <div className='flex middle'>
            <button
              className={`toggle-menu ${overlayMenuOpen ? 'close' : 'open'}`}
              onClick={() => setOverlayMenuOpen(!overlayMenuOpen)} />
          </div>
          <nav>
            {menuItems.map((item, i) => (
              <li key={i}>
                <NavLink to={item.uri} replace>{item.title}</NavLink>
              </li>
            ))}
          </nav>
        </div>
      </header>
      {overlayMenuOpen && <OverlayMenu
        onItemClick={() => setOverlayMenuOpen(false)}
        onBluredAreaClick={() => setOverlayMenuOpen(false)} />}
    </>
  );
}
