import { forwardRef, useCallback, useState } from 'react';
import './FileInput.scss';

const FileInput = forwardRef(function FileInput(props: {
  id: string,
  placeholder: string,
  required?: boolean,
}, ref: React.ForwardedRef<HTMLInputElement>) {
  const [fileName, setFileName] = useState('');

  const openFileDialog = useCallback(() => {
    (ref as React.RefObject<HTMLInputElement>)
      ?.current
      ?.click();
  }, [ref]);

  const setFile = useCallback((file: File | null | undefined) => {
    setFileName(file?.name || '');
  }, []);

  return (
    <div className='file-input-wrapper'>
      <input
        type='file'
        ref={ref}
        id={props.id}
        onChange={e => setFile(e.target.files?.item(0))}/>

      <input
        type='text'
        readOnly
        placeholder={props.placeholder}
        value={fileName}
        className='file-input'
        onClick={() => openFileDialog()}
        required={props.required} />

      <button
        type='button'
        className='choose-file'
        onClick={() => openFileDialog()}>
        Choose File
      </button>
    </div>
  );
});

export default FileInput;
