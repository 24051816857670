import './Technology.scss';
import technology from '../../../../data/technology';
import { useOverlayScrollbars } from 'overlayscrollbars-react';
import { useEffect, useRef } from 'react';

export default function Technology() {
  const osRef = useRef<HTMLDivElement>() as React.MutableRefObject<HTMLDivElement>;
  const [initializeOS] = useOverlayScrollbars({
    defer: true,
    options: {
      showNativeOverlaidScrollbars: false,
    },
    events: {
      initialized: os => {
        // Card dragging hack

        const { viewport } = os.elements();
        const draggingElement = viewport.firstChild! as HTMLElement;
        const left = () => draggingElement.getClientRects().item(0)?.x!;
        const initialLeft = left();
        let dragging = false;
        let startingX = 0;
        let startingLeft = 0;

        viewport.style.cursor = 'grab';

        viewport.onmousedown = e => {
          startingX = e.clientX;
          startingLeft = left();
          dragging = true;
          viewport.style.cursor = 'grabbing';
        };

        viewport.onmousemove = e => {
          if(!dragging) {
            return;
          }

          viewport.scrollLeft =
            -startingLeft - (e.clientX - startingX) + initialLeft;
        };

        viewport.onmouseup = e => {
          dragging = false;
          viewport.style.cursor = 'grab';
        };
      },
    }
  });

  useEffect(() => {
    if(osRef.current) {
      initializeOS(osRef.current);
    }
  }, [initializeOS, osRef]);

  return (
    <section className="technology">
      <div className='flex center'>
        <div className='base-width'>
          <h2 className='title'>Technologies</h2>
        </div>
      </div>
      <div className='flex center'>
        <div className='technology-list-wrapper base-width' ref={osRef}>
          <ul className='technology-list flex'>
            {technology.map((t, i) => (
              <li key={i}>
                <img src={t.image} alt='Technology' draggable={false} />
              </li>
            ))}
          </ul>
        </div>
      </div>
    </section>
  );
}
