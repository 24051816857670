const menuItems = [
  {
    "title": "Services",
    "uri": "/services",
    "external": false,
  },
  {
    "title": "Meet the team",
    "uri": "/team",
    "external": false,
  },
  {
    "title": "Contact",
    "uri": "/contact",
    "external": false,
  },
  {
    "title": "Career",
    "uri": "/career",
    "external": false,
  },
  {
    "title": "Blog",
    "uri": "https://blog.qafana.com",
    "external": true,
  }
];

export default menuItems;
