import step1 from './step1.svg';
import step2 from './step2.svg';
import step3 from './step3.svg';
import step4 from './step4.svg';
import step5 from './step5.svg';
import step6 from './step6.svg';
import step7 from './step7.svg';
import step8 from './step8.svg';

const steps = [
  {
    "title": "Framework Selection",
    "description": "Given your specific environment and your needs we can present to you one or more options on what are the best tools for test framework that you could use. We take into consideration speed, tech-stack, platform, type of testing, team size and your development process.",
    "image": step1,
  },
  {
    "title": "Prototype Development",
    "description": "We build a prototype with some basic functionalities to showcase to the team and get their input on the way framework is implemented.",
    "image": step2,
  },
  {
    "title": "Tool Evaluation",
    "description": "Given your specific environment and your needs we can present to you one or more options on what are the best tools for test framework that you could use. We take into consideration speed, tech-stack, platform, type of testing, team size and your development process.",
    "image": step3,
  },
  {
    "title": "Test Expansion & Integration",
    "description": "We continue adding tests and increasing coverage as well as communicate some specific needs and wants. Where you will be running tests, how often, how fast they need to be, where in SDLC process you want to fit them.",
    "image": step4,
  },
  {
    "title": "CI/CD Integration",
    "description": " We integrate the framework into your CI/CD pipeline and make them runnable on schedule or some specific event.",
    "image": step5,
  },
  {
    "title": "Reporting & Notifications",
    "description": "We integrate reporting with your communication tool of choice. You can utilize some existing tools, or we can use our own tools and hand them over for future use. We add notifications on Slack, Teams or email. We make reporting based on stakeholder needs.",
    "image": step6,
  },
  {
    "title": "Stakeholder Empowerment",
    "description": "We enable you to continue working on it and give the pulls and levers to stakeholders of your choice to be able to run them on their own. We expose parameters, so you can run subsets of tests and allow you to parametrize them by your needs.",
    "image": step7,
  },
  {
    "title": "Quality Assurance Commitment",
    "description": "We will try to make sure that you are free of worries when it comes to end quality of the software you deliver.",
    "image": step8,
  },
];

export default steps;
