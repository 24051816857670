import { Link } from 'react-router-dom';
import './ProcessAndCulture.scss';
import PuzzleBeans from './puzzle-beans.svg';

export default function ProcessAndCulture() {
  return (
    <section className="process-and-culture flex center">
      <div className="base-width grid">
        <img className='background' src={PuzzleBeans} alt='Puzzle beans' />
        <div className='card process'>
          <h2 className='title'>Process</h2>
          <p className='description'>
            At Qafana, as a QA agency, we follow a dedicated process of
            identifying and correcting defects in a product before release.
            Our team of experienced professionals work to ensure that the
            product meets the quality standards and continue to monitor and
            maintain the product even after release by collecting feedback
            and making updates as necessary.
          </p>
          <h3 className='eight-steps'>
            8 Steps in our process
            <Link to="/services#process">explained here</Link>
          </h3>
        </div>
        <div className='card culture'>
          <h2 className='title'>Culture</h2>
          <p className='description'>
            We are committed to delivering the highest quality services to
            our clients, and we use cutting-edge technology and innovative
            methods to achieve this. Despite our focus on high-end innovation,
            we have a down-to-earth approach when it comes to problem-solving,
            which allows us to understand and address client's needs in a
            practical and efficient way. At Qafana, our culture is built on
            the values of alertness, quality and innovation.
          </p>
          <Link to="/team" className='btn see-more'>Meet the team</Link>
        </div>
      </div>
    </section>
  );
}
