import './Intro.scss';
import BeansHangingAroung from './beans-hanging-around.svg';
import BeanWithTail from './images/bean-with-tail.svg';
import Magnifier from './images/magnifier.svg';
import LetterQ from './images/letter-q.svg';
import Bean from './images/bean.svg';
import { animated, easings, useTransition } from '@react-spring/web';
import { useState } from 'react';
import { Link } from 'react-router-dom';

interface AnimationState {
  frame: number,
  starting: boolean,
};

function Animation() {
  const frames = [
    BeanWithTail,
    Magnifier,
    Bean,
    LetterQ,
  ];

  const [animation, setAnimation] = useState({
    frame: 0,
    starting: true,
  } as AnimationState);

  const transitions = useTransition(animation.frame, {
    key: animation.frame,
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
    config: {
      duration: 2500,
      easing: easings.easeInExpo,
      progress: animation.starting ? 1 : 0,
    },
    onRest: (_a, _b, frame) => {
      if(animation.frame === frame) {
        setAnimation({
          ...animation,
          frame: (animation.frame + 1) % frames.length,
          starting: false,
        });
      }
    },
    exitBeforeEnter: false,
  });

  return (
    <div className='animation'>
      <div className='relative'>
        {transitions((style, index) => (
          <animated.img
            src={frames[index]}
            className='frame absolute'
            style={style}
            />
        ))}
      </div>
    </div>
  );
}

export default function Intro() {
  return (
    <section className='intro flex center'>
      <div className='base-width grid'>
        <div className='gradient'></div>
        <h1 className='title'>We are independent QA agency!</h1>
        <img className='background' src={BeansHangingAroung} alt='Qafana beans hanging around' />
        <Animation />
        <p className='description'>
          We provide unbiased and objective evaluations, identifying issues internal
          teams may have missed. We also bring specialized knowledge and experience to
          the testing process, having worked with diverse clients across industries.

          <Link to="/services" className='btn see-more'>See more</Link>
        </p>
      </div>
    </section>
  );
}
