import webdriverIOImage from "./technology/webdriver_io.png";
import jestImage from "./technology/jest.png";
import githuActionsImage from "./technology/github_actions.png";
import dockerImage from "./technology/docker.webp";
import mochaImage from "./technology/mocha.png";
import postmanImage from "./technology/postman.png";
import k6Image from "./technology/k6.svg";
import xrayImage from "./technology/xray.png";
import codesceneImage from "./technology/codescene.png";
import playwrightImage from "./technology/playwright.svg";
import seleniumImage from "./technology/selenium.svg";

const technology = [
  {
    "image": playwrightImage,
  },
  {
    "image": seleniumImage,
  },
  {
    "image": webdriverIOImage,
  },
  {
    "image": jestImage,
  },
  {
    "image": githuActionsImage,
  },
  {
    "image": dockerImage,
  },
  {
    "image": mochaImage,
  },
  {
    "image": postmanImage,
  },
  {
    "image": k6Image,
  },
  {
    "image": xrayImage,
  },
  {
    "image": codesceneImage,
  },
];

export default technology;
