const services = [
  {
    "title": "Regression",
    "description": "Regression testing is the diligent process of re-evaluating already tested software following modifications to ensure that new changes haven’t adversely affected existing features. It's about preserving the core functionalities that users have come to rely on, much like a barista ensures that the signature taste of a classic coffee blend remains unchanged, even as new brews are introduced.",
    "slug": "regression",
  },
  {
    "title": "Security",
    "description": "Security testing stands as the guardian of software integrity, rigorously checking for vulnerabilities and breaches that could compromise data and functionality. It's akin to a cautious coffee aficionado who takes measures to store their beans securely, ensuring that the quality remains untainted.",
    "slug": "security",
  },
  {
    "title": "Acceptance",
    "description": "Acceptance testing is the final seal of approval for software functionality, confirming that the product meets all specified requirements and is ready for deployment. It’s the step that decides whether the software is ready to 'serve' to end-users, not unlike the final taste test before a coffee batch is deemed ready for sale.",
    "slug": "acceptance",
  },
  {
    "title": "Automation",
    "description": "Automation testing is all about efficiency and reliability, using automated processes to execute predefined test cases. This approach is about maximizing productivity and consistency, ensuring software performance is thoroughly checked with precision, reminiscent of an automated coffee machine delivering your preferred choice at the press of a button.",
    "slug": "automation",
  },
  {
    "title": "Usability",
    "description": "Usability testing evaluates the user interface and user experience of software, ensuring that it's intuitive, user-friendly, and accessible. This testing makes sure that the product is easy to navigate and pleasant to use, similar to the way a coffee shop might arrange its space to be welcoming and comfortable for guests.",
    "slug": "usability",
  },
  {
    "title": "Performance",
    "description": "Performance testing measures the responsiveness, stability, scalability, and speed of software under various conditions. It assesses whether the software can handle high loads and stress without compromising performance, ensuring that it can keep up with the demands of users, just as a high-quality coffee grinder must perform under the morning rush without faltering.",
    "slug": "performance",
  },
  {
    "title": "Localization",
    "description": "Localization testing ensures that software is adapted correctly for different languages, cultures, and regions, providing a local feel to a global product. It verifies that the software resonates with its intended audience, in much the same way that coffee blends are tailored to local tastes around the world.",
    "slug": "localization",
  },
  {
    "title": "Accessibility",
    "description": "Accessibility testing verifies that software products are usable by people with a wide range of abilities, including those with disabilities. It ensures that the software is inclusive and considers various user needs, which is essential for creating a product that's as universally accessible as a cafe’s mainstay, the classic cup of coffee.",
    "slug": "accessibility",
  },
  {
    "title": "Functional",
    "description": "Functional testing is focused on the various functions of a software system, ensuring that it operates according to requirements. It involves detailed checks of all actions, inputs, outputs, and integrations, making sure every aspect of the software works as intended, just as each element of a coffee machine must function correctly to produce the perfect brew.",
    "slug": "functional",
  },
  {
    "title": "Compatibility",
    "description": "Compatibility testing assesses how software behaves and interacts with other systems, software, hardware, or environments. It is crucial for determining whether the software can operate in different settings, ensuring a smooth and harmonious user experience, similar to how different coffee ingredients are tested for compatibility to create the perfect blend.",
    "slug": "compatibility",
  },
];

export default services;
