import './Home.scss';
// import Endorsements from "./Endorsements/Endorsements";
import Intro from "./Intro/Intro";
import ProcessAndCulture from "./ProcessAndCulture/ProcessAndCulture";
import Services from "./Services/Services";
import Technology from "./Technology/Technology";

export default function Home() {
  return (
    <div className="page home">
      <Intro />
      <Services />
      <ProcessAndCulture />
      <Technology />
      {/* <Endorsements /> */}
    </div>
  );
}
