import { useLocation } from "react-router-dom";
import usePrevious from "./usePrevious";
import { useEffect } from "react";
import { Location } from 'react-router-dom';

export default function useLocationChange(
    action: (
      currentLocation: Location,
      previousLocation: Location
    ) => void
  ) {
  const _location = useLocation();
  const _previousLocation = usePrevious(_location);

  useEffect(() => {
    action(_location, _previousLocation);
  }, [action, _location, _previousLocation]);
}
