import React from 'react';
import ReactDOM from 'react-dom/client';
import '@csstools/normalize.css'
import './assets/fonts/satoshi/css/satoshi.css'
import './index.scss';
import Qafana from './Qafana';
import { BrowserRouter } from 'react-router-dom';
import ReactGA from 'react-ga4';

ReactGA.initialize('G-12TG9FG9CF');

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Qafana />
    </BrowserRouter>
  </React.StrictMode>
);
