import './Career.scss';
import HomeIcon from './home-icon.svg';
import CoffeeIcon from './coffee-icon.svg';
import { useEffect, useState } from 'react';
import axios from 'axios';
import FormFeedback, { FeedbackType } from '../../FormFeedback/FormFeedback';
import bytes from 'bytes';
import positions, { CareerPositionType } from '../../../data/career/positions';

export const FormInputs = {
  acceptPrivacyPolicy: 'acceptPrivacyPolicy',
  resume: 'resume',
};

const ResumeMaxSize = bytes.parse('5MB');

interface CareerState {
  position: number,
  submitting: boolean,
  submitted: boolean,
  feedback: string,
  feedbackType: FeedbackType,
};

const initialState: CareerState = {
  position: 0,
  submitting: false,
  submitted: false,
  feedback: '',
  feedbackType: 'success',
};

export default function Career() {
  const [state, setState] = useState<CareerState>(initialState);

  const changePosition = (index: number) => {
    if(state.submitting) {
      return;
    }

    setState({...state, position: index});
  };

  const submitHandler = async (data: FormData, positionType: CareerPositionType) => {
    if(state.submitting || state.submitted) {
      return;
    }

    const resume = data.get(FormInputs.resume);

    if(!(resume instanceof File)) {
      setState({
        ...state,
        feedbackType: 'error',
        feedback: 'Please choose your resume file',
      });

      return;
    }

    if(resume.size > ResumeMaxSize) {
      setState({
        ...state,
        feedbackType: 'error',
        feedback: `Maximum size for resume is ${bytes.format(ResumeMaxSize)}`,
      });

      return;
    }

    if(data.get(FormInputs.acceptPrivacyPolicy) !== 'true') {
      setState({
        ...state,
        feedbackType: 'error',
        feedback: 'Privacy policy needs to be accepted',
      });

      return;
    }

    try {
      setState({ ...state, feedback: '', submitting: true });

      await axios({
        method: 'post',
        url: positions.find(p => p.type === positionType)?.endpoint,
        data,
        headers: { "Content-Type": "multipart/form-data" },
      });

      setState({
        ...state,
        submitting: false,
        submitted: true,
        feedbackType: 'success',
        feedback: 'Form successfully submitted',
      });
    }
    catch(error: any) {
      setState({
        ...state,
        submitting: false,
        submitted: false,
        feedbackType: 'error',
        feedback: error?.message || 'Error occured. Please try again later',
      });
    }
  };

  useEffect(() => {
    if(state.feedback.length) {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  }, [state.feedback]);

  useEffect(() => {
    setState({...initialState, position: state.position});
  }, [state.position]);

  return (
    <div className='page career flex middle column'>
      <h2 className='title base-width'>Looking for a coworker</h2>

      <div className='wrapper base-width flex'>
        <div className='form-wrapper'>
          <h1 className='title'>Career</h1>

          <ul className='positions'>
            {positions.map((p, i) => (
              <li key={i} className={[
                  'position',
                  i === state.position ? 'active' : ''
                ].join(' ').trim()}>
                <button
                  type='button'
                  onClick={() => changePosition(i)}>
                  {p.title}
                </button>
              </li>
            ))}
          </ul>

          {state.feedback && <FormFeedback
            type={state.feedbackType}
            onDismiss={() => setState({...state, feedback: ''})}>
            {state.feedback}
          </FormFeedback>}

          <>
            {positions.map((p, i) => (
              i === state.position
              && <p.form
                key={i}
                onSubmit={(data: FormData) => submitHandler(data, p.type)}
                submitting={state.submitting}
                submitted={state.submitted} />
            ))}
          </>
        </div>
        <div className='benefits-wrapper flex center'>
          <ul className='benefits'>
            <li>
              <img src={HomeIcon} alt='work-from-home' />
              <div className='info'>
                <h3 className='title'>Flexible work hours!</h3>
                <p className='description'>
                  Adjust work to your lifestyle. Not the other way around!
                </p>
              </div>
            </li>
            <li>
              <img src={CoffeeIcon} alt='free-coffee' />
              <div className='info'>
                <h3 className='title'>Coffee in the office</h3>
                <p className='description'>
                  Infinite number of coffee cups in the office
                </p>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}
