import React, { useState } from "react";

const HoverImage = ({ initialSrc, hoverSrc, alt }: { initialSrc: string, hoverSrc: string, alt: string }) => {
  const [src, setSrc] = useState(initialSrc);

  return (
    <img 
      src={src} 
      alt={alt}
      onMouseEnter={() => setSrc(hoverSrc)} 
      onMouseLeave={() => setSrc(initialSrc)} 
    />
  );
};

export default HoverImage;