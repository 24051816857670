import { MouseEventHandler } from 'react';
import './Button.scss';
import Spinner from '../../assets/icons/spinner.svg';
import Check from '../../assets/icons/check.svg';

interface ButtonProperties {
  className?: string;
  text: string;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  type?: "button" | "submit";
  loading?: boolean;
  done?: boolean;
};

export default function Button(props: ButtonProperties) {
  return (
    <button
      className={`btn ${props.className || ''} ${props.loading ? ' loading' : ''}`.trim()}
      onClick={props.onClick}
      type={props.type || 'button'}>
        {props.loading === true
          ? <img src={Spinner} alt='Loading...' className='spinner' />
          : props.done === true
            ? <img src={Check} alt='Submitted' />
            : props.text}
    </button>
  );
}
