import './Contact.scss';
import Button from "../../Button/Button";
import MapUrl from './map.svg';
import LinkedInIcon from './linkedin.svg';
import EmailIcon from './email.svg';
import PhoneIcon from './phone.svg';
import LocationIcon from './location.svg';
import React, { useEffect, useId, useRef, useState } from 'react';
import axios from 'axios';
import { faker } from '@faker-js/faker';
import FormFeedback, { FeedbackType } from '../../FormFeedback/FormFeedback';
import ReCAPTCHA from 'react-google-recaptcha';

interface ContactState {
  sending: boolean,
  submitted: boolean,
  feedback: string,
  feedbackType: FeedbackType,
};

export default function Contact() {
  const recaptchaRef = useRef<ReCAPTCHA>(null);

  const fullNameId = useId();
  const emailId = useId();
  const companyId = useId();
  const messageId = useId();

  const fullNameRef = useRef<HTMLInputElement>(null);
  const emailRef = useRef<HTMLInputElement>(null);
  const companyRef = useRef<HTMLInputElement>(null);
  const messageRef = useRef<HTMLInputElement>(null);

  const [state, setState] = useState<ContactState>({
    sending: false,
    submitted: false,
    feedback: '',
    feedbackType: 'success',
  });

  const handleSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault();

    if(state.sending || state.submitted) {
      return;
    }

    const formData = new FormData();

    formData.append('name', fullNameRef.current!.value);
    formData.append('email', emailRef.current!.value);
    formData.append('company', companyRef.current!.value);
    formData.append('message', messageRef.current!.value);

    try {
      setState({...state, feedback: '', sending: true});

      const token = await recaptchaRef.current?.executeAsync();
      recaptchaRef.current?.reset();

      formData.append('recaptchaToken', token!);

      await axios({
        method: 'post',
        url: process.env.REACT_APP_API_ENDPOINT_CONTACT,
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
      });

      setState({
        ...state,
        sending: false,
        submitted: true,
        feedbackType: 'success',
        feedback: 'Form successfully submitted',
      });
    }
    catch(error: any) {
      setState({
        ...state,
        sending: false,
        feedbackType: 'error',
        feedback: error?.message || 'Error occured. Please try again later',
      });
    }
  };

  useEffect(() => {
    if(state.feedback.length) {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  }, [state.feedback]);

  useEffect(() => {
    // Fill the form with random data
    // in development environment for easier testing

    if(process.env.NODE_ENV === 'development') {
      if(fullNameRef.current) {
        fullNameRef.current.value = faker.name.fullName();
      }

      if(emailRef.current) {
        emailRef.current.value = faker.internet.exampleEmail();
      }

      if(companyRef.current) {
        companyRef.current.value = faker.company.name();
      }

      if(messageRef.current) {
        messageRef.current.value = faker.lorem.paragraph();
      }
    }
  }, [fullNameRef, emailRef, companyRef, messageRef]);

  return (
    <div className="page contact">
      <div className="flex center">
        <div className="base-width flex sections-wrapper">
          <div className={`form-wrapper ${state.feedback.length ? 'with-feedback' : ''}`}>
            <h1 className="title">Let's work together</h1>

            {state.feedback && <div className='feedback-wrapper'>
              <FormFeedback
                type={state.feedbackType}
                onDismiss={() => setState({...state, feedback: ''})}>
                {state.feedback}
              </FormFeedback>
            </div>}

            <form onSubmit={handleSubmit}>
              <ReCAPTCHA
                sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY!}
                size='invisible'
                ref={recaptchaRef}
              />

              <h2 className="form-title">Contact us</h2>
              <div className='group required'>
                <label htmlFor={fullNameId}>Full name</label>
                <input
                  type='text'
                  placeholder="Full name"
                  id={fullNameId}
                  ref={fullNameRef}
                  required />
              </div>
              <div className='group required'>
                <label htmlFor={emailId}>Email</label>
                <input
                  type='email'
                  placeholder="Email"
                  id={emailId}
                  ref={emailRef}
                  required />
              </div>
              <div className='group'>
                <label htmlFor={companyId}>Company</label>
                <input
                  type='text'
                  placeholder="Company"
                  id={companyId}
                  ref={companyRef} />
              </div>
              <div className='group required'>
                <label htmlFor={messageId}>Message</label>
                <input
                  type='text'
                  placeholder="Leave a message"
                  id={messageId}
                  ref={messageRef}
                  required />
              </div>
              <div className='group buttons flex'>
                <Button
                  text="Send"
                  className='submit'
                  type='submit'
                  loading={state.sending}
                  done={state.submitted} />
              </div>
            </form>
          </div>
          <div className='social-wrapper'>
            <div className='header'>
              <h3 className='title'>Contact us</h3>

              <div className='icons'>
                <a href="https://www.linkedin.com/company/qafana/" target="_blank" rel="noopener noreferrer">
                  <img src={LinkedInIcon} alt='LinkedIn' />
                </a>
                <a href="mailto:info@qafana.com">
                  <img src={EmailIcon} alt='Email' />
                </a>
                <a href="tel:+38762876923">
                  <img src={PhoneIcon} alt='Phone' />
                </a>
                <a href="https://www.google.com/maps/place/Sarajevo,+Bosnia+and+Herzegovina/@43.8400234,18.3257575,17z" target="_blank" rel="noopener noreferrer">
                  <img src={LocationIcon} alt='Location' />
                </a>

              </div>
            </div>
            <div className='map'>
              <img src={MapUrl} alt='map' />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
