import 'overlayscrollbars/overlayscrollbars.css';
import './Qafana.scss';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import { Link, Outlet, Route, Routes } from 'react-router-dom';
import Home from './components/pages/Home/Home';
import Contact from './components/pages/Contact/Contact';
import ProcessAndServices from './components/pages/ProcessAndServices/ProcessAndServices';
import Career from './components/pages/Career/Career';
import Team from './components/pages/Team/Team';
import useLocationChange from './hooks/useLocationChange';

function ScrollToTop() {
  useLocationChange((current, previous) => {
    const noHash = current.hash.length === 0;

    if(noHash || current.pathname !== previous.pathname) {
      window.scrollTo(0, 0);
    }

    if(noHash) {
      return;
    }

    setTimeout(() => {
      const id = current.hash.replace('#', '');
      const element = document.getElementById(id);
      if (element) {
        element.scrollIntoView({
          behavior: 'smooth',
        });
      }
    }, 250);
  });

  return null;
}

export default function Qafana() {
  return (
    <>
      <ScrollToTop />
      <Routes>
        <Route element={<Layout />}>
          <Route index element={<Home />} />
          <Route path='/services/:service?' element={<ProcessAndServices />} />
          <Route path='/team' element={<Team />} />
          <Route path='/contact' element={<Contact />} />
          <Route path='/career' element={<Career />} />
          <Route path='*' element={<PageNotFound />} />
        </Route>
      </Routes>
    </>
  );
}

function Layout() {
  return (
    <div className='qafana flex column'>
      <Header />
      <div className='main'>
        <Outlet />
      </div>
      <Footer />
    </div>
  )
}

function PageNotFound() {
  return (
    <div className='page-not-found flex center'>
      <div>
        <h2>Nothing to see here!</h2>
        <p>
          <Link to="/" className='go-to-home'>Go to the home page</Link>
        </p>
      </div>
    </div>
  );
}
