import { useTransition, animated } from '@react-spring/web';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import './Services.scss';
import services from '../../../../data/services';
import AnalyticBeans from './analytic-beans.svg';

function AnimatedServices() {
  const [serviceIndex, setServiceIndex] = useState(0);
  const borderColor = '41, 50, 44';

  const transitions = useTransition(serviceIndex, {
    from: { borderColor: `rgba(${borderColor}, 0.1)` },
    enter: { borderColor: `rgba(${borderColor}, 0.7)` },
    leave: { borderColor: `rgba(${borderColor}, 0.1)` },
    onRest: (_a, _b, index) => {
      if(serviceIndex === index) {
        setServiceIndex((serviceIndex + 1) % services.length);
      }
    },
    exitBeforeEnter: true,
  });

  return (
    <ul className='services-list flex compact-scrollbar'>
      {transitions((style, index) => (
        services.map((s, i) => (
          index === i
            ? <animated.li key={i} style={style}>{s.title} testing</animated.li>
            : <li key={i}>{s.title} testing</li>
        ))
      ))}
    </ul>
  );
}

export default function Services() {
  return (
    <section className="services flex center">
      <div className='base-width grid'>
        <img className='background' src={AnalyticBeans} alt="Analytic beans" />
        <h2 className='title'>Services</h2>
        <p className='description'>
          At Qafana, we improve clients' QA and test automation
          through streamlined processes and expert professionals.
          We strive to deliver top-quality services.
        </p>
        <AnimatedServices />
        <div className='see-more-wrapper flex'>
          <Link to="/services" className='btn see-more'>See more</Link>
        </div>
      </div>
    </section>
  );
}
