import HoverImage from '../../HoverImage/HoverImage';
import './Team.scss';
import Member1 from './team-member-1.svg';
import Member2 from './team-member-2.svg';
import Member3 from './team-member-3.svg';
import Member4 from './team-member-4.svg';
import { Link } from 'react-router-dom';

export default function Team() {
  return (
    <div className='page team flex wrap center'>
      <div className='base-width'>
        <div className='row r1 flex base-width'>
          <div className='intro'>
            <h1 className='title'>Who is roasting code and coffee beans?</h1>
            <p className='description'>
              At Qafana, we improve clients' QA and test automation through
              streamlined processes and expert professionals. We strive to
              deliver top-quality services.
            </p>
          </div>
          <TeamMember name='Emir Hodzic' image={Member1} hoverImage={Member2} index={1}>
            Everything is automatable, even your coffee machine!
          </TeamMember>
        </div>
        <div className='row r2 flex base-width'>
          <TeamMember name='Alex Rose' image={Member2} hoverImage={Member2} index={2}>
            Automate everything! (except coffee machines, that's Emir's job)
          </TeamMember>
          <TeamMember name='Brian Lyew' image={Member3} hoverImage={Member3} index={3}>
            Let's brew together! (pun intended)
          </TeamMember>
          <TeamMember name='Bilal Bandic' image={Member4} hoverImage={Member4} index={4}>
            The only tea guy in the team... Yeah we know...
          </TeamMember>
        </div>
        <div className='row r3 join flex center base-width'>
          <h2 className='title'>If you like us, join!</h2>
          <Link to='/career' className='btn'>Join the team</Link>
        </div>
      </div>
    </div>
  );
}

function TeamMember(props: {
  name: string,
  image: string,
  hoverImage: string,
  children: string,
  index: number,
}) {
  return (
    <div className={`team-member member-${props.index}`}>
      <HoverImage initialSrc={props.image} hoverSrc={props.hoverImage} alt={props.name}></HoverImage>

      <div className='info'>
        <div className='wrapper'>
          <h2 className='name'>{props.name}</h2>
          <p className='description'>{props.children}</p>
        </div>
      </div>
    </div>
  );
}
