import './Logo.scss';

export default function Logo() {
  return (
    <div className='logo'>
      <svg className='image' viewBox={`0 0 36 39`} fill={'currentColor'} xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M15.4351 22.756L22.2458 30.3631C23.8731 32.1808 22.8338 35.1017 20.4191 35.443C19.5959 35.5593 18.7535 35.6194 17.8984 35.6194C8.05557 35.6181 0.111033 27.7669 0.00110029 17.9254C-0.0602574 12.3278 2.44901 7.31308 6.42064 3.99082C8.3189 2.40319 11.2168 3.56771 11.4584 6.03096L12.449 16.1243L12.6331 17.997C12.7494 19.1871 13.2428 20.3081 14.0405 21.1991L15.4351 22.756ZM16.39 3.92818L17.5596 15.8328C17.6759 17.0229 18.1694 18.144 18.967 19.0349L26.8783 27.873C28.5733 29.7661 31.6003 29.513 32.9719 27.3732C34.7577 24.5891 35.7931 21.2771 35.7931 17.7234C35.7931 8.93395 29.4515 1.65156 21.1017 0.113785C20.8704 0.0716014 20.5738 0.0370877 20.2415 0.011522C18.0172 -0.164881 16.1727 1.70908 16.39 3.92946V3.92818Z" />
        <path fillRule="evenodd" clipRule="evenodd" d="M25.8658 34.5867C24.9241 33.5347 25.0135 31.9184 26.0655 30.9767C27.1175 30.0349 28.7337 30.1243 29.6755 31.1763L32.8163 34.6848C33.7581 35.7368 33.6687 37.3531 32.6167 38.2949C31.5647 39.2366 29.9484 39.1472 29.0067 38.0952L25.8658 34.5867Z" />
      </svg>
      <h2 className='text'>afana</h2>
    </div>
  );
}
