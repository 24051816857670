import { useEffect, useId, useRef } from "react";
import FileInput from "../../../FileInput/FileInput";
import Button from "../../../Button/Button";
import { faker } from '@faker-js/faker';
import { FormInputs } from "../Career";
import ReCAPTCHA from 'react-google-recaptcha';

export default function AlwaysLookingForm(props: {
  onSubmit: (data: FormData) => Promise<void>,
  submitting: boolean,
  submitted: boolean,
}) {
  const recaptchaRef = useRef<ReCAPTCHA>(null);

  const fullNameId = useId();
  const emailId = useId();
  const phoneId = useId();
  const resumeId = useId();
  const acceptPrivacyPolicyId = useId();
  const linkedInId = useId();

  const fullNameRef = useRef<HTMLInputElement>(null);
  const emailRef = useRef<HTMLInputElement>(null);
  const phoneRef = useRef<HTMLInputElement>(null);
  const resumeRef = useRef<HTMLInputElement>(null);
  const acceptPrivacyPolicyRef = useRef<HTMLInputElement>(null);
  const linkedInRef = useRef<HTMLInputElement>(null);

  const handleSubmit = async (e: React.SyntheticEvent) => {
    e.preventDefault();

    const formData = new FormData();

    formData.append('name', fullNameRef.current!.value);
    formData.append('email', emailRef.current!.value);
    formData.append('phone', phoneRef.current!.value);
    formData.append(
      FormInputs.resume,
      resumeRef.current!.files?.length
        ? resumeRef.current!.files[0]
        : '');
    formData.append(
      FormInputs.acceptPrivacyPolicy,
      acceptPrivacyPolicyRef.current!.checked ? 'true' : 'false');
    formData.append('linkedInProfile', linkedInRef.current!.value);

    const token = await recaptchaRef.current?.executeAsync();
    recaptchaRef.current?.reset();

    formData.append('recaptchaToken', token!);

    await props.onSubmit(formData);
  };

  useEffect(() => {
    // Fill the form with random data
    // in development environment for easier testing

    if(process.env.NODE_ENV === 'development') {
      if(fullNameRef.current) {
        fullNameRef.current.value = faker.name.fullName();
      }

      if(emailRef.current) {
        emailRef.current.value = faker.internet.exampleEmail();
      }

      if(phoneRef.current) {
        phoneRef.current.value = faker.phone.number();
      }

      if(linkedInRef.current) {
        linkedInRef.current.value = `https://linkedin.com/in/${faker.internet.userName()}`;
      }

      if(acceptPrivacyPolicyRef.current) {
        acceptPrivacyPolicyRef.current.checked = true;
      }
    }
  }, [fullNameRef, emailRef, phoneRef, linkedInRef]);

  return (
    <form onSubmit={handleSubmit}>
      <ReCAPTCHA
        sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY!}
        size='invisible'
        ref={recaptchaRef}
      />

      <div className='group required'>
        <label htmlFor={fullNameId}>Full name</label>
        <input ref={fullNameRef} type='text' placeholder="Full name" id={fullNameId} required />
      </div>
      <div className='group required'>
        <label htmlFor={emailId}>Email</label>
        <input ref={emailRef} type='email' placeholder="Email" id={emailId} required />
      </div>
      <div className='group required'>
        <label htmlFor={phoneId}>Phone</label>
        <input ref={phoneRef} type='text' placeholder="Phone" id={phoneId} required />
      </div>
      <div className='group required'>
        <label htmlFor={resumeId}>Resume</label>
        <FileInput ref={resumeRef} placeholder="Resume" id={resumeId} required />
      </div>
      <div className='group privacy-policy flex'>
        <div>
          <input
            ref={acceptPrivacyPolicyRef}
            type='checkbox'
            className='styled'
            id={acceptPrivacyPolicyId} />
          <label htmlFor={acceptPrivacyPolicyId} />
        </div>
        <label htmlFor={acceptPrivacyPolicyId}>
          U skladu sa Zakonom o zaštiti ličnih podataka (član br. 5.)
          dajem saglasnost za korištenje mojih ličnih podataka u svrhu
          selekcije kandidata za posao i informisanja o novim konkursima.
          (Zakon o zaštiti ličnih podataka je
          dostupan <a href="https://fzs.ba/wp-content/uploads/2016/06/zakon_podaci.pdf" target='_blank' rel='noreferrer'>ovdje</a>).
        </label>
      </div>
      <div className='group'>
        <label htmlFor={linkedInId}>LinkedIn profile</label>
        <input ref={linkedInRef} type='text' placeholder="Leave a message" id={linkedInId} />
      </div>
      <div className='group buttons flex'>
        <Button
          text="Submit application"
          className='submit'
          type='submit'
          loading={props.submitting}
          done={props.submitted} />
      </div>
    </form>
  );
}
