import AlwaysLookingForm from "../../components/pages/Career/positionForms/AlwaysLookingForm";
import QaAutomationEngineerForm from "../../components/pages/Career/positionForms/QaAutomationEngineerForm";

export enum CareerPositionType {
  general,
  qaEngineer,
};

export interface CareerPosition {
  type: CareerPositionType,
  title: string,
  form: React.FC<any>,
  endpoint: string,
};

const positions: CareerPosition[] = [
  {
    type: CareerPositionType.general,
    title: 'Always Looking',
    form: AlwaysLookingForm,
    endpoint: process.env.REACT_APP_API_ENDPOINT_CAREER_GENERAL!,
  },
  {
    type: CareerPositionType.qaEngineer,
    title: 'QA Automation Engineer',
    form: QaAutomationEngineerForm,
    endpoint: process.env.REACT_APP_API_ENDPOINT_CAREER_QA_ENGINEER!,
  }
];

export default positions;
