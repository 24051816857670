import { Link } from 'react-router-dom';
import Logo from '../Logo/Logo';
import './Footer.scss';
import services from '../../data/services';
import { SVGProps } from 'react';

export default function Footer() {
  const halfOfServices = Math.floor(services.length / 2);

  return (
    <footer className='flex center'>
      <div className='base-width blocks flex'>
        <div className='block services'>
          <h3 className='title'>Testing Services</h3>
          <nav className='content flex'>
            <ul>
              {services.slice(0, halfOfServices).map((s, i) => (
                <li key={i}><Link to={`services/${s.slug}#list`}>{s.title}</Link></li>
              ))}
            </ul>
            <ul>
              {services.slice(halfOfServices).map((s, i) => (
                <li key={i}><Link to={`services/${s.slug}#list`}>{s.title}</Link></li>
              ))}
            </ul>
          </nav>
        </div>
        <div className='block company'>
          <h3 className='title'>Company</h3>
          <nav className='content'>
            <li><Link to="/team">Culture</Link></li>
            <li><Link to="/#endorsements">Satisfied Clients</Link></li>
            <li><Link to="/contact">Contact Us</Link></li>
            <li><Link to="/career">Career</Link></li>
          </nav>
        </div>
        <div className='block main'>
          <div className='content'>
            <div className='logo-wrapper'>
              <Logo />
              <p className='moto'>Let us roast your code!</p>
            </div>
            <div className='button-wrapper'>
              <Link to="/contact" className='btn'>Get in touch</Link>
            </div>
            <div className='block social-media'>
            <a href="https://github.com/qafana" target="_blank" rel="noopener noreferrer">
              <GithubIcon />
            </a>
            <a href="https://www.linkedin.com/company/qafana" target="_blank" rel="noopener noreferrer">
              <LinkedinIcon />
            </a>
            </div>
          </div>
        </div>

      </div>
    </footer>
  );
}


const GithubIcon = (props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    viewBox="0 0 512 512"
    {...props}
  >
    <circle cx={256} cy={256} r={256} fill="#333" />
    <path
      fill="#FFF"
      d="M256 93.9c-89.5 0-162.1 72.6-162.1 162.1 0 70.5 45 130.4 107.8 152.8.3.1 1.4.5 1.7.6.9.3 1.9.5 2.9.5 5.3 0 9.5-4.3 9.5-9.5v-29c-10.3 2.1-25.9 4.1-34.4 0-11-5.3-16.6-12.1-21.9-25.5-6.6-16.3-21.8-20.8-22.4-23.6-.6-2.9 16.1-7.2 24.7 2.7 8.6 9.9 17.3 29.7 35.8 27.8 9.1-.9 15-2.4 18.7-3.5.7-6.4 2.8-14.3 8.1-19.9-43.5-7.2-72.5-30.6-72.5-76.5 0-20.9 6-37.1 16.6-49.2-1.8-10-4.8-33.2 3.2-41.3 0 0 11.3-7 43.7 15.8 12.1-2.8 25.5-4.2 39.8-4.2h1.6c14.4.1 27.8 1.5 39.8 4.2 32.4-22.8 43.7-15.8 43.7-15.8 8 8.2 5 31.4 3.2 41.3 10.6 12.2 16.6 28.4 16.6 49.2 0 45.9-28.9 69.3-72.5 76.5 8.3 8.7 8.6 22.8 8.6 28.6v42.5c0 5.3 4.3 9.5 9.5 9.5.8 0 1.5-.1 2.3-.3.2 0 .8-.2 1-.3 63.5-22 109.1-82.3 109.1-153.3 0-89.7-72.6-162.2-162.1-162.2z"
    />
  </svg>
)

const LinkedinIcon = (props: JSX.IntrinsicAttributes & SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={48}
    height={48}
    fill="none"
    {...props}
  >
    <path
      fill="#0077B5"
      d="M0 24C0 10.745 10.745 0 24 0s24 10.745 24 24-10.745 24-24 24S0 37.255 0 24Z"
    />
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M17.319 14.823c0 1.569-1.181 2.824-3.078 2.824h-.035c-1.825 0-3.006-1.255-3.006-2.824C11.2 13.22 12.416 12 14.277 12c1.86 0 3.007 1.22 3.042 2.823Zm-.358 5.055V36.22h-5.44V19.878h5.44ZM36.575 36.22v-9.37c0-5.02-2.683-7.356-6.262-7.356-2.888 0-4.18 1.586-4.902 2.699v-2.315h-5.44c.072 1.534 0 16.342 0 16.342h5.44v-9.127c0-.488.035-.975.179-1.325.393-.976 1.288-1.986 2.79-1.986 1.97 0 2.757 1.499 2.757 3.695v8.743h5.438Z"
      clipRule="evenodd"
    />
  </svg>
)
