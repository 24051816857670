import './ProcessAndServices.scss';
import steps from '../../../data/process/steps';
import services from '../../../data/services';
import { useCallback, useEffect, useRef, useState } from 'react';
import Typewriter from 'typewriter-effect/dist/core';
import { animated, easings, useSpring, useSpringRef, useTransition } from '@react-spring/web';
import ServicesIntroMagnifier from './services-intro-magnifier.svg';
import ServicesIntroBackground from './services-intro-background.svg';
import CupOfCoffee from './cup-of-coffee.svg';
import { useParams } from 'react-router-dom';

export default function ProcessAndServices() {
  return (
    <div className='page process-and-services'>
      <Services />
      <Process />
    </div>
  );
}

function Services() {
  const { service } = useParams();
  const requestedServiceIndex = services.findIndex(s => s.slug === service);
  const respectRequestedService = useRef(true);
  const [serviceIndex, setServiceIndex] = useState(0);
  const serviceTitle = useRef<HTMLElement>(null);

  useEffect(() => {
    new Typewriter(serviceTitle.current, {})
      .typeString(services[serviceIndex].title)
      .start();
  }, [serviceTitle, serviceIndex]);

  const goToService = (index: number) => {
    setServiceIndex(index);
  };

  const goToNextService = useCallback(() => {
    goToService((serviceIndex + 1) % services.length);
  }, [serviceIndex]);

  useEffect(() => {
    respectRequestedService.current = true;
  }, [requestedServiceIndex]);

  useEffect(() => {
    if(respectRequestedService.current && requestedServiceIndex >= 0) {
      setServiceIndex(requestedServiceIndex);
      respectRequestedService.current = false;
    }

    const interval = setInterval(goToNextService, 7500);

    return () => clearInterval(interval);
  }, [goToNextService, requestedServiceIndex, serviceIndex]);

  return (
    <section className='services'>
      <div className='flex center'>
        <div className='base-width grid'>
          <img className='background' src={ServicesIntroBackground} alt='Services intro background' />
          <img className='background magnifier' src={ServicesIntroMagnifier} alt='Services intro magnifier' />
          <div className='intro'>
            <h1 className='title'>Services</h1>
            <p className='description'>
              At Qafana, we improve clients' QA and test automation through
              streamlined processes and expert professionals. We strive to
              deliver top-quality services and solutions.
            </p>
          </div>
          <ul className='services-list flex wrap' id='list'>
            {services.map((s, i) => (
              <li key={i} className="service">
                <button
                  type='button'
                  className={i === serviceIndex ? 'active' : ''}
                  onClick={() => goToService(i)}>
                  {s.title}
                </button>
              </li>
            ))}
          </ul>
          <img className='cup-off-coffee' src={CupOfCoffee} alt='Cup of coffee' />
          <div className='info'>
            <h2 className='title'>
              <span className='dynamic' ref={serviceTitle}>{services[serviceIndex].title}</span>
              <span className='suffix'>testing</span>
            </h2>
            <p className='description'>
              {services[serviceIndex].description}
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

function Process() {
  const defaultProgressDuration = 3000;
  const progressDuration = useRef(defaultProgressDuration);
  const [stepIndex, setStepIndex] = useState(0);
  const stepsWrapper = useRef<HTMLDivElement>(null);
  const touchMoving = useRef(false);
  const touchStartX = useRef(0);
  const touchStartY = useRef(0);

  const [imageTransitions, imageApi] = useTransition(steps[stepIndex], () => ({
    from: {
        opacity: 0,
        transform: 'scale(0.6, 1.2)',
    },
    enter: {
      opacity: 1,
      transform: 'scale(1, 1)',
    },
    leave: {
      opacity: 0,
      transform: 'scale(0.3, 0.3)',
    },
    config: {
      easing: easings.easeOutBack,
      duration: 1500,
    },
    exitBeforeEnter: false,
  }));

  const progressApi = useSpringRef();
  const progress = useSpring({
    ref: progressApi,
    from: { width: '0%' },
    to: { width: '100%' },
    config: {
      easing: easings.linear,
      duration: progressDuration.current,
    },
  });

  const goToStep = useCallback((index: number, manual: boolean = false) => {
    progressApi.stop();
    progressApi.set({ width: '0%' });

    if(manual) {
      progressDuration.current = defaultProgressDuration * 3;
    }

    setStepIndex(index);
  }, [progressApi]);

  const goToNextStep = useCallback(() => {
    goToStep((stepIndex + 1) % steps.length);
  }, [goToStep, stepIndex]);

  const goToPreviousStep = useCallback(() => {
    goToStep(stepIndex - 1 < 0 ? steps.length - 1 : stepIndex - 1);
  }, [goToStep, stepIndex]);


  useEffect(() => {
    Promise.all(imageApi.start()).then(animations => {
      if(! animations.every(a => a.finished)) {
        return;
      }

      Promise.all(progressApi.start()).then(animations => {
        if(! animations.every(a => a.finished)) {
          return;
        }

        // Progress finished by its own, revert duration to default
        progressDuration.current = defaultProgressDuration;

        goToNextStep();
      });
    });
  }, [goToNextStep, progressApi, stepIndex]);

  useEffect(() => {
    const element = stepsWrapper.current;

    if(element == null) {
      return;
    }

    element.ontouchstart = e => {
      const { clientX, clientY } = e.touches[0];
      touchMoving.current = true;
      touchStartX.current = clientX;
      touchStartY.current = clientY;

      progressApi.pause();
    };

    element.ontouchend = e => {
      const { clientX, clientY } = e.changedTouches[0];
      touchMoving.current = false;

      progressApi.resume();

      const diffX = clientX - touchStartX.current;
      const diffY = clientY - touchStartY.current;

      if(Math.abs(diffX) < 30 || Math.abs(diffY) > 30) {
        return;
      }

      if(diffX > 0) {
        goToPreviousStep();
      } else {
        goToNextStep();
      }
    };
  }, [goToNextStep, goToPreviousStep, progressApi, stepIndex, stepsWrapper]);

  return (
    <section className='process flex wrap center' id="process">
      <div className='main-wrapper flex center'>
        <div className='base-width grid'>
          {(imageTransitions((style, step) => (
            <animated.img
              className='animated-image'
              src={step.image}
              style={style}
              alt={step.title} />
          )))}
          <div className='intro'>
            <h1 className='title'>Process</h1>
            <h2 className='description'>
              We can be tightly integrated with your team and
              work all the way through the process of brewing solutions:
            </h2>
          </div>
          <div className='steps-wrapper' ref={stepsWrapper}>
            <div className='indicator'>
              <span className='current-step'>{stepIndex + 1}.</span>
              <span className='total-steps'>/{steps.length}</span>
            </div>
            <ul className='steps'>
              {steps.map((s, i) => (
                <ProcessStep
                  key={i}
                  title={s.title}
                  description={s.description}
                  active={i === stepIndex} />
              ))}
            </ul>
            <ul className='navigator flex'>
              {steps.map((_, i) => (
                <li key={i}>
                  <button
                    type='button'
                    className={i === stepIndex ? 'active' : ''}
                    onClick={() => goToStep(i, true)}>
                      <animated.span
                        className='progress'
                        style={{ width: i !== stepIndex ? 0 : progress.width }} />
                  </button>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
}

function ProcessStep(props: {
  title: string,
  description: string,
  active: boolean,
}) {
  return (
    <li className={`step ${props.active ? 'active' : ''}`}>
      <h3 className='title'>{props.title}</h3>
      <p className='description'>{props.description}</p>
    </li>
  );
}
